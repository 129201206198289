<template>
  <v-app>
    <!--システムバー今回は未使用-->
    <v-system-bar>
      <v-icon icon="mdi-wifi-strength-4"></v-icon>
      <v-icon icon="mdi-signal" class="ms-2"></v-icon>
      <v-icon icon="mdi-battery" class="ms-2"></v-icon>
      <span class="ms-2">{{ hhmm }}</span>
    </v-system-bar>
    <!--アプリケーションバーログアウト等-->
    <v-app-bar color="primary" dark app clipped-left v-if="store.getters.getIsLogin">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title to="/">{{ store.getters.getSelected.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-menu offset-y>
          <template v-slot:activator="{ props }">
            <v-btn text v-bind="props">
              {{ store.getters.getNickname }}
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item prepend-icon="mdi-account-circle" title="プロファイル" to="/profile"></v-list-item>
            <v-list-item prepend-icon="mdi-logout" title="ログオフ" @click="logoff"></v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <!--コンテンツエリア-->
    <v-main>
      <router-view :key="componentKey" />
    </v-main>
    <!--フッターモバイルデバイスのみ表示-->
    <div class="d-sm-none">
      <v-footer app color="primary" v-if="store.getters.getIsLogin">
        <v-spacer></v-spacer>
        <v-btn v-for="item in store.getters.getTabsItem" :key="item.id" variant="flat" height="40" class="text-caption"
          stacked :prepend-icon="item.icon" @click="activate(item.id)">
          {{ item.label }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-footer>
    </div>
    <!--ナビゲーション拠点の切替に使用-->
    <v-navigation-drawer v-if="store.getters.getIsLogin" v-model="drawer" width="300">
      <TreeView :count="componentKey" @set:newCount="setNewCount" />
    </v-navigation-drawer>
  </v-app>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, provide } from "vue";
import TreeView from "@/components/TreeView.vue";    // 拠点のツリー表示
const index = ref(0);         // コンテンツのタブ切替 
const drawer = ref(null);     // ナビゲーションバーのレンダリング切替
const componentKey = ref(0);  // 拠点切替時コンテンツの再描画
drawer.value = false;         // ナビゲーションバーを初期表示しない

// ルータの初期化
import { useRouter } from "vue-router";
const router = useRouter();

// ストアの初期化
import { useStore } from "vuex";
const store = useStore();

// ログオフ処理
const logoff = () => {
  router.push("/");
  store.commit("setIsLogin", false);
  // alert("Login:" + store.getters.getIsLogin);
};

// インターバルタイマー処理
import { useDate } from "vuetify";
const date = useDate();
const hhmm = ref(1);
var interval = -1;
// インターバルタイマー開始
onMounted(() => {
  interval = setInterval(() => {
    hhmm.value = date.format(new Date(), "fullTime24h");
  }, 1000);
});
// インターバルタイマー停止
onBeforeUnmount(() => {
  clearInterval(interval);
});
// コンテンツの再描画
const setNewCount = (newCount) => {
  // console.log("old:" + componentKey.value + " new:" + newCount);
  componentKey.value = newCount;
  drawer.value = false;
};
// フッターアイコン押下時処理
const activate = (id) => {
  // console.log('press : ' + id);
  index.value = id;
}
// コンテンツ画面とリアクティブ変数を共有
provide('activateTab', index);
</script>
