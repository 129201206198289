<template>
  <v-treeview :items="items" item-value="id" activatable open-on-click density="compact" @click="onSelected" slim
    width="300">
    <template v-slot:prepend="{ item, isOpen }">
      <v-icon v-if="!item.type">
        {{ isOpen ? "mdi-folder-open" : "mdi-folder" }}
      </v-icon>
      <v-icon v-else @click="console.log('icon');"> {{ types[item.type] }} </v-icon>
    </template>
  </v-treeview>
</template>

<script setup>
import { VTreeview } from "vuetify/labs/VTreeview";
import { ref, defineProps, defineEmits } from "vue";
// import { ref } from "vue";

// ストアの初期化
import { useStore } from "vuex";
const store = useStore();

const types = ref({
  1: "mdi-folder",
  2: "mdi-folder",
  3: "mdi-folder",
  4: "mdi-file-document-outline",
});


// 親コンポーネントから渡されるカウント
const props = defineProps({
  count: Number
})
// 子コンポーネントのカウントを管理するリアクティブ変数
const childCount = ref(props.count);

const emit = defineEmits(['set:newCount']);


// ツリーデータ取得
const items = ref(store.getters.getDomain);

// サーバー通信の初期化
import axios from "axios";
const url = store.getters.getBaseURL + "license";

// ツリーノードタイトルinnerTextよりノードのidを取得
const onSelected = (e) => {
  var domainId = getDomainId(e.target.innerText, items.value);
  // console.log(domainId);
  // axios.defaults.headers.common["X-API-KEY"] = store.getters.getAuthToken;

  axios
    .get(url, {
      params: {
        uid: store.getters.getUserId,
        did: domainId,
        token: store.getters.getAuthToken
      },
      headers: {
        'Content-Type': 'application/json',
      },

    })
    .then(function (response) {
      // console.log(response.status);
      if (response.status == 200) {
        store.commit("setSelected", {
          id: domainId,
          title: e.target.innerText,
          type: 4,
          license: response.data.license
        });
        // 親コンポーネントにイベントを発火し、新しいカウントを通知
        childCount.value++;
        emit('set:newCount', childCount.value);
      }
    })
    .catch(function (error) {
      console.log(error);
      alert("サーバーに接続できません。" + error.message);
    });

};

// ツリーデータよりタイトルのidを取得
const getDomainId = function (title, parent) {
  var domainId;

  for (var domain of parent) {
    if (domain.title == title) {
      return domain.id;
    } else {
      if (domain.children !== undefined) {
        domainId = getDomainId(title, domain.children);
        if (domainId !== undefined) return domainId;
      }
    }
  }
};

</script>